/*fonts*/
@font-face {
  font-family: 'GothamBold';
  src: url(fonts/GothamBold.otf);
}

@font-face {
  font-family: 'GothamBook';
  src: url(fonts/GothamBook.otf);
}

@font-face {
  font-family: 'Galvji';
  src: url(fonts/Galvji.ttc);
}

@font-face {
  font-family: 'FairProsper';
  src: url(fonts/FairProsper.ttf);
}

p {
  line-height: normal;
  font-size: 1rem;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.mt-scroll {
  scroll-margin-top: 86px;
}

.mt-n5 {
  margin-top: -5px;
}

.mw-300 {
  max-width: 300px;
}

.mw-700 {
  max-width: 700px;
}

.primary {
  color: #003594;
}

.secondary {
  color: #79a3db;
}

.danger {
  color: #f48574;
}

.nav-list {
  font-family: 'GothamBook';
  font-size: 12px;
}

.nav-link {
  color: #79a3db !important;
}

.nav-link-active {
  font-family: 'GothamBold';
  color: #f48574 !important;
}

.social-btn {
  background-color: #f48574;
}

.social-icon {
  width: 30px;
}

.header-logo {
  width: 150px;
}

.footer-logo {
  width: 50px;
}

.section-paragraph {
  text-align: justify;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* .section1 {
  height: 100vh;
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/section1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
} */

.section1 img {
  margin-top: 50px;
  width: 450px;
}

.section2-1 {
  padding: 100px;
}

.section2 img {
  width: 250px;
}

.side-image {
  width: 100% !important;
}

.section6 {
  height: 80vh;
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/section6.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.section7 {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/section7.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.headers-font {
  font-family: 'GothamBold';
}

.links-font {
  text-decoration: none;
  font-family: 'GothamBold';
  border-bottom: 2px solid #f48574;
  display: inline-block !important;
}

.form-header {
  font-family: 'FairProsper';
  font-size: 3rem;
}

.form-labels {
  font-family: 'GothamBold';
  color: #f48574;
  font-size: 12px;
}

.form-justify-div {
  justify-content: flex-start !important;
}

.form-checkbox {
  font-family: "GothamBold";
  color: #fff;
  font-size: 0.5rem;
}

.form-checkbox label {
  max-width: 250px;
}

.form-btn {
  font-family: "GothamBold";
}

.form-btn:hover {
  background-color: #f48574;
  border-color: #f48574;
  color: #fff;
}

.parallax {
  /* The image used */
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/bg-parallax1.webp");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax2 {
  /* The image used */
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/bg-parallax2.webp");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn:hover {
  background-color: #f5583f;
  border-color: #f5583f;
}

/* Small devices (e.g., smartphones) */
@media (max-width: 576px) {

  .mw-700 {
    max-width: 320px;
  }

  .mobile-header {
    background-color: #fff0 !important;
  }

  .mobile-header button {
    border: none;
    filter: brightness(0) invert(1);
  }

  .mobile-mt-30 {
    margin-top: 30px;
  }

  /* Styles for small devices */
  .section1 {
    height: 235px;
    background-size: contain;
  }

  .section1 img {
    margin-top: -5px;
    width: 200px;
  }

  .section2 {
    padding: 0px !important;
  }

  .section2 img {
    width: 200px;
  }

  .section2-1 {
    padding: 25px 50px 25px 50px;
  }

  .section2 .side-image,
  .section3 .side-image {
    height: 300px;
    margin-bottom: 10px;
  }

  .p-section4 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .section3 .link1 {
    margin-top: 10px !important;
  }

  .links-font {
    font-size: 15px !important;
    margin: 0px 0px 5px 0px !important;
  }

  .section5 {
    padding: 0px !important;
  }

  .fs-5 {
    font-size: 0.9rem !important;
  }

  .section7 {
    padding-top: 0px !important;
  }

  .section7 .w-50 {
    width: 100% !important;
  }

  .form-justify-div {
    justify-content: space-between !important;
  }

  .form-checkbox {
    font-size: 0.45rem;
  }

  .form-checkbox label {
    max-width: 150px;
  }

  .socials-footer {
    margin-top: 15px;
  }

  .parallax {
    background-attachment: unset !important;
    min-height: 370px;
  }

  .video-background {
    height: 25vh;
  }
}

/* Medium devices (e.g., tablets) */
@media (max-width: 767px) {

  /* Styles for medium devices */
  .socials-footer {
    margin-top: 15px;
  }
}